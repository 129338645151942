import { z } from 'zod';

export const teamKindResultBaseSchema = z.object({
  /**
   * Only STP account has team_id as null
   */
  team_id: z.number().or(z.null()),
});

export type TeamKindResultBase = z.infer<typeof teamKindResultBaseSchema>;
