import { AppProps } from 'next/app';

import 'cropperjs/dist/cropper.css';
import { appWithTranslation } from 'next-i18next';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

import { RootProvider } from '@/RootProvider';
import '@/styles/citrus-loader.scss';
import '@/styles/reset.scss';

import { i18n, localePath } from '../../next-i18next.config';
import '../polyfills';

// Should use NODE_ENV === 'development' for tree shaking on production
if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_MSW === 'true') {
  require('#/mocks');
}

export type ComponentProps = {
  skipAuth?: boolean;
  skipNewVersion?: boolean;
  skipI18n?: boolean;
  showTryBeforeCommit?: boolean;
  disableOsanoScript?: boolean;
};

type MyAppProps = AppProps & {
  Component: ComponentProps;
};

function MyApp({ Component, pageProps }: MyAppProps) {
  return (
    <RootProvider
      skipNewVersion={Boolean(Component.skipNewVersion)}
      showTryBeforeCommit={Boolean(Component.showTryBeforeCommit)}
      skipAuth={Boolean(Component.skipAuth)}
    >
      <Component {...pageProps} />
    </RootProvider>
  );
}

const FilterAppWithTranslation = (props: MyAppProps) => {
  const { Component } = props;

  // Skip server side i18n
  // mainly for ignoring team app pages, due to its special environment, can only use static build and client-side fetch i18n
  // and for some pages need static export, ex: web client
  if (Component.skipI18n) {
    return <MyApp {...props} />;
  }

  const AppWithTrans = appWithTranslation(MyApp, { i18n, localePath });
  return AppWithTrans(props);
};

export default FilterAppWithTranslation;
