import { featureControl } from '@/feature/toggle';
import { getRegion as getRegion_new } from '@/feature/toggle/region';

type EnvironmentConfig = {
  env: 'global' | 'eu' | 'oc';
  appUrl: string;
  sosDownloadLink: string;
  sosTrialDays: string;
  sosPrice: string;
};

const globalConfig: EnvironmentConfig = {
  env: 'global',
  appUrl: 'my.splashtop.com',
  sosDownloadLink: 'https://sos.splashtop.com',
  sosTrialDays: '7',
  sosPrice: '$199.00',
};

const europeConfig: EnvironmentConfig = {
  env: 'eu',
  appUrl: 'my.splashtop.eu',
  sosDownloadLink: 'https://sos.splashtop.eu',
  sosTrialDays: '14',
  sosPrice: '€189,00',
};

const ocConfig: EnvironmentConfig = {
  env: 'oc',
  appUrl: 'my.splashtop.nr',
  sosDownloadLink: 'https://sos.splashtop.nr',

  // NOTE: Currently OC stack follows the same as global stack
  sosTrialDays: '7',
  sosPrice: '$285.00',
};

const configMap: Record<Region, EnvironmentConfig> = {
  global: globalConfig,
  eu: europeConfig,
  oc: ocConfig,
};

const defaultCurrencyLocaleMap: Record<Region, string> = {
  global: 'en-US',
  eu: 'id-ID',
  oc: 'en-US',
};

const defaultCurrencyMap: Record<Region, string> = {
  global: 'USD',
  eu: 'EUR',
  oc: 'AUD',
};

export type regionDataOptions = {
  currency?: string;
  currencyLocale?: string;
  sosPrice?: number;
};

export type Region = 'global' | 'eu' | 'oc';

/**
 * @deprecated Use `getRegion` from `@/feature/toggle/region` instead
 */
export const getRegion = (): Region => `${(process.env.NEXT_PUBLIC_REGION as Region) || 'global'}`; // Add template literal to avoid vite build time error, ref: https://main.vitejs.dev/guide/env-and-mode.html#production-replacement

export const getRegionData = (options?: regionDataOptions) => {
  if (featureControl.getToggle('PCP_3896__SOS_Customization_ShareIframe')) {
    const regionFromEnvironment = getRegion_new();
    const config: EnvironmentConfig = configMap[regionFromEnvironment];

    if (options?.sosPrice) {
      // NOTE: Currently OC stack follows the same as global stack
      const currencyLocale = options?.currencyLocale ?? defaultCurrencyLocaleMap[regionFromEnvironment];
      const currency = options?.currency ?? defaultCurrencyMap[regionFromEnvironment];
      const price = Math.round(options.sosPrice * Math.pow(10, 2)) / Math.pow(10, 2);
      config.sosPrice = new Intl.NumberFormat(currencyLocale, { style: 'currency', currency })
        .format(price)
        .replace('CA$', 'CAD$')
        .replace('A$', 'AUD$');
    }

    return config;
  }

  const regionFromEnvironment = getRegion();
  const config: EnvironmentConfig = regionFromEnvironment === 'eu' ? europeConfig : globalConfig;

  if (options?.sosPrice) {
    const currencyLocale = options?.currencyLocale ?? (regionFromEnvironment === 'eu' ? 'id-ID' : 'en-US');
    const currency = options?.currency ?? (regionFromEnvironment === 'eu' ? 'EUR' : 'USD');
    const price = Math.round(options.sosPrice * Math.pow(10, 2)) / Math.pow(10, 2);
    config.sosPrice = new Intl.NumberFormat(currencyLocale, { style: 'currency', currency })
      .format(price)
      .replace('CA$', 'CAD$')
      .replace('A$', 'AUD$');
  }

  return config;
};
