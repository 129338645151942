import { cssVar } from '@chakra-ui/react';
import type { ComponentMultiStyleConfig } from '@chakra-ui/react';

// https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%401.8.8/packages/theme/src/components/switch.ts

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

const styles: ComponentMultiStyleConfig = {
  parts: ['container', 'track', 'thumb'],
  baseStyle: {
    track: {
      bg: 'neutral.80',
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _checked: {
        bg: 'blue.100',
      },
    },
  },
  sizes: {
    md: {
      container: {
        [$width.variable]: '40px',
        [$height.variable]: '20px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export const rippleSwitch = styles;
