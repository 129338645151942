import type { ComponentStyleConfig } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

const styles: ComponentStyleConfig = {
  parts: ['list', 'item', 'divider'],
  baseStyle: {
    list: {
      shadow: '8px',
      borderRadius: '4px',
      py: '6px',
      px: featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? '8px' : '0px',
      minWidth: '128px',
      maxWidth: featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? '90vw' : '100%',
      maxHeight: '320px',
    },
    item: {
      px: featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? '8px' : '12px',
      borderRadius: featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? '4px' : '0px',
      display: 'flex',
      gap: featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? '8px' : '0px',
      py: '6px',
      // NOTE: Chakra MenuItem using focus to emulate hover behavior,
      //       if you define `_hover` style, it will let `_active` style being overwrite.
      //       According Chakra default theme style, we need to use `_focus` to implement hover style.
      // _hover: {
      //   bg: 'blue.0',
      // },
      color: 'dark.90',
      _active: {
        bg: 'blue.10',
        _disabled: {
          bg: 'transparent',
        },
      },
      _focus: {
        bg: 'blue.0',
      },
    },
    divider: {
      my: '6px',
    },
  },
  sizes: featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu')
    ? {
        xs: {
          item: {
            py: '4px',
            minH: '32px',
          },
        },
        sm: {
          item: {
            py: '8px',
            minH: '40px',
          },
        },
      }
    : {},
  defaultProps: {
    size: 'sm',
  },
};

export const rippleMenu = styles;
