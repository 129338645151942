import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleLog16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77083 3.00045H11.5625V7.06292H12.8333V12.4658C12.8333 12.4635 12.8333 12.4611 12.8333 12.4588V11.3296V3.00045C12.8333 2.29859 12.2644 1.72961 11.5625 1.72961H2.77083C2.06897 1.72961 1.5 2.29859 1.5 3.00045V12.4588C1.5 13.1606 2.06897 13.7296 2.77083 13.7296H10.3148H11.5625C11.5654 13.7296 11.5683 13.7296 11.5713 13.7296H6.83331V12.4588H2.77083V3.00045Z"
        fill="currentColor"
      />
      <rect x="4.16663" y="4.39624" width="6" height="1.33333" rx="0.635417" fill="currentColor" />
      <rect x="4.16663" y="7.06287" width="5.33333" height="1.33333" rx="0.635417" fill="currentColor" />
      <rect x="4.16663" y="9.72961" width="3.33333" height="1.33333" rx="0.635417" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 15.0629C13.3409 15.0629 14.8333 13.5705 14.8333 11.7296C14.8333 9.88862 13.3409 8.39624 11.5 8.39624C9.65901 8.39624 8.16663 9.88862 8.16663 11.7296C8.16663 13.5705 9.65901 15.0629 11.5 15.0629ZM12.1354 10.0629C12.1354 9.71195 11.8509 9.42746 11.5 9.42746C11.149 9.42746 10.8646 9.71195 10.8646 10.0629V11.7295C10.8646 11.9702 11.0005 12.1902 11.2158 12.2979L12.5491 12.9645C12.863 13.1215 13.2447 12.9943 13.4016 12.6804C13.5586 12.3665 13.4314 11.9848 13.1175 11.8279L12.1354 11.3368V10.0629Z"
        fill="currentColor"
      />
    </Icon>
  );
}
