import { useEffect } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { FallbackRender, ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';

import { AuthUserProvider } from '@/AuthUserProvider';
import { RippleFlashMessageStandaloneContainer } from '@/design';
import theme from '@/design/theme';
import NewVersionToast from '@/modules/NewVersionToast';
import { TryBeforeCommit } from '@/modules/TryBeforeCommit';
import chakraCodeModeManager from '@/utils/chakraCodeModeManager';
import { makeMSTeamsLoginUrl } from '@/utils/constants';
import i18nInstance from '@/utils/i18n';
import isInIframe from '@/utils/isInIframe';

import { featureControl } from './feature/toggle';
import { RudderStackTracking } from './modules/RudderStackTracking';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

type RootProviderProps = { children?: React.ReactNode; skipNewVersion: boolean; showTryBeforeCommit: boolean; skipAuth: boolean };
export function RootProvider({ children, skipNewVersion, showTryBeforeCommit, skipAuth }: RootProviderProps): React.JSX.Element {
  useEffect(() => {
    if (isInIframe()) {
      return;
    }
    // Avoid entering the pages during the login process in Teams app.
    const vendorId = sessionStorage.getItem('vendor_id');
    if (vendorId !== null && vendorId !== undefined) {
      window.location.href = makeMSTeamsLoginUrl({
        serverHost: window.location.origin,
        vendorId,
      });
    }
  }, []);

  return (
    <SentryErrorBoundary
      fallback={UnexpectedErrorFallback}
      beforeCapture={(scope) => {
        scope.setTag('top-unexpected-error', 'yes');
      }}
    >
      <IsolateJotaiStateFromEachRequest>
        <ChakraProvider theme={theme} colorModeManager={chakraCodeModeManager}>
          <RippleFlashMessageStandaloneContainer />

          <QueryClientProvider client={queryClient}>
            {!skipNewVersion && <NewVersionToast />}
            {showTryBeforeCommit && <TryBeforeCommit />}
            {skipAuth ? (
              children
            ) : (
              <AuthUserProvider>
                {featureControl.getToggle('PCP_4105__RudderStack_add_team_info') ? (
                  <RudderStackTracking>{children}</RudderStackTracking>
                ) : (
                  children
                )}
              </AuthUserProvider>
            )}
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ChakraProvider>
      </IsolateJotaiStateFromEachRequest>
    </SentryErrorBoundary>
  );
}

function IsolateJotaiStateFromEachRequest({ children }: { children: React.ReactNode }) {
  return <JotaiProvider>{children}</JotaiProvider>;
}

const UnexpectedErrorFallback: FallbackRender = ({ eventId }) => {
  return (
    <main style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <p>
        {i18nInstance?.t('common:unexpectedError')} (Event ID: {eventId})
      </p>
    </main>
  );
};
