import { BoxProps, Flex, forwardRef, useMenuContext, useStyleConfig } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import { RippleArrowDown16, RippleArrowUp16, RippleInlineArrowDown, RippleInlineArrowUp } from '../RippleIcon';

type Variant = 'standard' | 'grayScale';

export type RippleInlineSelectButtonWithMenuContextProps = BoxProps & {
  isDisabled?: boolean;
  variant?: Variant;
  size?: 'md' | 'sm';
};

const InlineButtonWithMenuContext = forwardRef<RippleInlineSelectButtonWithMenuContextProps, 'button'>((props, ref) => {
  const { isOpen } = useMenuContext();

  return <RippleInlineSelectButton ref={ref} isOpen={isOpen} {...props} />;
});

type RippleInlineSelectButtonProps = { isOpen: boolean } & RippleInlineSelectButtonWithMenuContextProps;
/**
 * @deprecated should use `RippleSelectButton` with variant `ghost` instead.
 */
export const RippleInlineSelectButton = forwardRef<RippleInlineSelectButtonProps, 'button'>(
  ({ isOpen, children, variant = 'standard', size = 'md', ...otherProps }, ref) => {
    const styles = useStyleConfig('rippleInlineSelectButton', { variant, size });

    return (
      <Flex
        as="button"
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        gap="4px"
        ref={ref}
        __css={styles}
        {...otherProps}
      >
        {children}
        {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
          <>{isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}</>
        ) : (
          <>{isOpen ? <RippleInlineArrowUp color="inherit" /> : <RippleInlineArrowDown color="inherit" />}</>
        )}
      </Flex>
    );
  },
);

export const RippleInlineSelectButtonWithMenuContext = InlineButtonWithMenuContext;
