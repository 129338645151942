import type { ComponentStyleConfig } from '@chakra-ui/react';

const styles: ComponentStyleConfig = {
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: {
    overlay: {
      bgColor: 'dark.90',
      // NOTE: opacity prop won't work,
      //       because Chakra use frame-motion's AnimatePresence that using opacity within inline style,
      //       so define opacity through style props will be overwrite.
      //       We use `filter: opacity(0.8)` to replace `opacity: 0.8`
      // opacity: 0.8,
      filter: 'opacity(0.8)',
    },
    dialogContainer: {},
    dialog: {
      shadow: '16px',
      borderRadius: '4px',
      maxHeight: '80vh', // TODO: To be confirm
    },
    header: {
      padding: '24px',
    },
    closeButton: {
      top: '24px',
      right: '24px',
      borderRadius: '4px',
    },
    body: {
      padding: '0 24px 24px',
    },
    footer: {
      borderTop: '1px solid',
      borderTopColor: 'neutral.60',
      padding: '16px 24px',
    },
  },
  sizes: {
    '2xl': {
      dialog: {
        width: '512px',
      },
    },
    xl: {
      dialog: {
        width: '448px',
      },
    },
    lg: {
      dialog: {
        width: '384px',
      },
    },
    md: {
      dialog: {
        width: '320px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};

export const rippleModal = styles;
