import { featureControl } from '@/feature/toggle';

import { rippleTypography } from './rippleTypography';

export const rippleRadio = {
  parts: ['container', 'control', 'label'],
  baseStyle: {
    container: {
      alignItems: 'flex-start',
    },
    control: {
      bg: 'white',
      borderColor: 'neutral.300',
      borderStyle: 'solid',
      borderWidth: '1px',
      _hover: {
        bg: 'blue.0',
      },
      _checked: {
        bg: 'white',
        color: 'blue.100',
        borderColor: 'neutral.300',
        '::before': {
          width: '14px',
          height: '14px',
        },
        _hover: {
          bg: 'blue.0',
          borderColor: 'neutral.300',
        },
        _disabled: {
          bg: 'white',
          borderColor: 'dark.40',
          color: 'dark.40',
          _hover: {
            bg: 'white',
          },
        },
      },
      _disabled: {
        bg: 'white',
        borderColor: 'dark.40',
        color: 'dark.40',
        cursor: 'not-allowed',
        _hover: {
          bg: 'white',
        },
      },
    },
    label: featureControl.getToggle('PCP_3743__Export_Selected_Users')
      ? {
          width: '100%',
        }
      : {},
  },
  sizes: {
    lg: {
      label: {
        ...rippleTypography.variants.body02,
      },
    },
  },
};
