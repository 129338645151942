import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { Box, MenuItem, MenuProps, StyleProps, SystemStyleObject, UseFormControlProps, forwardRef } from '@chakra-ui/react';

import { RippleCheckbox } from '../RippleCheckbox';
import { RippleMenuItem, RippleMenuItemProps } from '../RippleMenu';
import { RippleTypography } from '../RippleTypography';
import type { RippleSelectButtonVariant } from './RippleSelectButton';

type RippleSelectType = 'single' | 'multiple';
type RippleSelectValue<T extends RippleSelectType> = (T extends 'single' ? string : Array<string>) | undefined;
type RippleSelectOnChange<T extends RippleSelectType> = (optionValue: RippleSelectValue<T>) => void;

export type RippleSelectProps = {
  children: React.ReactNode;
  placeholder?: React.ReactNode;
  variant?: RippleSelectButtonVariant;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  helperText?: string;
  boxSx?: SystemStyleObject;
  menuListSx?: SystemStyleObject;
  menuListProps?: {
    appendUnderPortal?: boolean; // Whether the menu list should be appended to the portal. To Prevent Select is under container with overflow: 'hidden'
    placeInModal?: boolean;
  };
  menuProps?: {
    isOpen?: MenuProps['isOpen'];
    placement?: MenuProps['placement'];
    matchWidth?: MenuProps['matchWidth'];
  };
  customLabel?: React.ReactNode; // Custom label for the select button
} & StyleProps &
  UseFormControlProps<HTMLButtonElement>;

type RippleSelectContextProps<T extends RippleSelectType = RippleSelectType> = {
  onSelect: (optionValue: string) => void;
  type: T;
  currentValue: RippleSelectValue<T>;
  registerOptionLabel: (optionValue: string, optionLabel: React.ReactNode) => void;
  labelMap: Record<string, React.ReactNode>;
  labelList: Array<{ optionValue: string; optionLabel: React.ReactNode }>;
};

export const RippleSelectContext = createContext<RippleSelectContextProps>({} as never);

type RippleSelectContextProviderProps<T extends RippleSelectType> = {
  type: T;
  children: React.ReactNode;
  value: RippleSelectValue<T>;
  onChange: RippleSelectOnChange<T>;
};

export function RippleSelectContextProvider({
  children,
  type,
  value,
  onChange,
}: RippleSelectContextProviderProps<'single'> | RippleSelectContextProviderProps<'multiple'>): React.JSX.Element {
  const [labelList, setLabelList] = useState<Array<{ optionValue: string; optionLabel: React.ReactNode }>>([]);
  const labelValueList = labelList.map(({ optionValue }) => optionValue);
  const labelMap = labelList.reduce(
    (acc, { optionValue, optionLabel }) => {
      acc[optionValue] = optionLabel;
      return acc;
    },
    {} as Record<string, React.ReactNode>,
  );

  const registerOptionLabel = useCallback((optionValue: string, optionLabel: React.ReactNode): void => {
    setLabelList((pre) => [...pre, { optionValue, optionLabel }]);
  }, []);

  function onSelect(optionValue: string): void {
    switch (type) {
      case 'single': {
        onChange(optionValue);
        break;
      }
      case 'multiple': {
        if (Array.isArray(value)) {
          const newValue = value.includes(optionValue)
            ? value.filter((selectedOption) => selectedOption !== optionValue)
            : value.concat(optionValue).sort((a, b) => labelValueList.indexOf(a) - labelValueList.indexOf(b));
          onChange(newValue);
        } else {
          onChange([optionValue]);
        }
        break;
      }
    }
  }

  return (
    <RippleSelectContext.Provider value={{ onSelect, currentValue: value, type, labelList, labelMap, registerOptionLabel }}>
      {children}
    </RippleSelectContext.Provider>
  );
}

type RippleSelectLabelProps = { placeholder: React.ReactNode; customLabel?: string | React.ReactNode };
export function RippleSelectLabel({ placeholder, customLabel }: RippleSelectLabelProps): React.JSX.Element {
  const { type, currentValue: value, labelMap } = useContext(RippleSelectContext);

  const label = generateLabel();
  function generateLabel(): React.ReactNode {
    switch (type) {
      case 'single': {
        if (typeof value === 'string') return labelMap[value] ?? value;
        return placeholder;
      }
      case 'multiple': {
        if (Array.isArray(value) && value?.length > 0) {
          return value.map((selectedOption) => labelMap[selectedOption] ?? selectedOption).join(', ');
        }
        return placeholder;
      }
    }
  }
  return (
    <Box as="span" data-testid="ripple-select-label" className="ripple-select-label">
      {customLabel ?? label}
    </Box>
  );
}

export const useIsSelected = ({ value }: { value: RippleSelectOptionProps['value'] }) => {
  const { currentValue, type } = useContext(RippleSelectContext);

  const isSelected = checkIsSelected();
  function checkIsSelected(): boolean {
    if (type === 'single' && typeof currentValue === 'string') {
      return currentValue === value;
    }
    if (type === 'multiple' && Array.isArray(currentValue)) {
      return currentValue.includes(value);
    }
    return false;
  }

  return isSelected;
};

export type RippleSelectOptionProps = Omit<RippleMenuItemProps, 'isSelected' | 'children'> & { value: string; children: React.ReactNode };
export const RippleSelectOption = forwardRef<RippleSelectOptionProps, 'button'>(({ value, children, ...otherProps }, ref) => {
  const { onSelect, registerOptionLabel } = useContext(RippleSelectContext);

  const isSelected = useIsSelected({ value });

  useEffect(() => {
    registerOptionLabel(value, children);
  }, [children, registerOptionLabel, value]);

  return (
    <RippleMenuItem ref={ref} isSelected={isSelected} onClick={() => onSelect(value)} {...otherProps}>
      {children}
    </RippleMenuItem>
  );
});

export const RippleSelectOptionWithCheckbox = forwardRef<RippleSelectOptionProps, 'button'>(({ value, children, ...otherProps }, ref) => {
  const { onSelect, registerOptionLabel } = useContext(RippleSelectContext);

  const isSelected = useIsSelected({ value });

  useEffect(() => {
    registerOptionLabel(value, children);
  }, [children, registerOptionLabel, value]);

  // TODO: PCP-4080
  return (
    <MenuItem
      ref={ref}
      onClick={(e) => {
        e.preventDefault(); // Prevent trigger checkbox's onclick handler
        onSelect(value);
      }}
      {...otherProps}
    >
      <RippleCheckbox isReadOnly isChecked={isSelected}>
        <RippleTypography as="span" variant="body02">
          {children}
        </RippleTypography>
      </RippleCheckbox>
    </MenuItem>
  );
});
