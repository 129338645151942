import { Flex, Portal, forwardRef, useControllableState, useFormControl, useMultiStyleConfig } from '@chakra-ui/react';

import { RippleMenu, RippleMenuButton, RippleMenuDivider, RippleMenuGroup, RippleMenuList } from '../RippleMenu';
import { RippleTypography } from '../RippleTypography';
import { RippleButtonProps } from './../RippleButton';
import { RippleSelectContextProvider, RippleSelectLabel, RippleSelectOption } from './RippleSelect';
import type { RippleSelectOptionProps, RippleSelectProps } from './RippleSelect';
import { RippleSelectButtonWithContext } from './RippleSelectButton';

export type RippleSingleSelectProps<T extends string = string> = Omit<RippleButtonProps, 'onChange' | 'placeholder' | 'variant'> &
  RippleSelectProps & {
    value: T | undefined;
    onChange: (value: T | undefined) => void;
  };

function RippleSingleSelectCore(props: RippleSingleSelectProps, ref: React.ForwardedRef<any>) {
  const {
    children,
    value,
    placeholder,
    onChange,
    variant = 'border',
    size = 'sm',
    helperText,
    boxSx: _boxSx, // shouldn't pass into RippleMenuButton
    menuListSx,
    menuListProps,
    menuProps: _, // shouldn't pass into RippleMenuButton
    customLabel,
    ...menuButtonProps
  } = props;

  const {
    menuListProps: _menuListProps,
    menuProps,
    boxSx,
    menuListSx: _menuListSx,
    customLabel: _customLabel,
    ...formControlProps
  } = props;

  const styles = useMultiStyleConfig('rippleSelect', { variant, size });

  const formProps = useFormControl<HTMLButtonElement>(formControlProps);
  const { id: _id, onFocus: _onFocus, onBlur: _onBlur, ...helperTextProps } = formProps;

  const MenuList = (
    <RippleMenuList zIndex={menuListProps?.placeInModal ? 'modal' : 'dropdown'} position="relative" sx={menuListSx}>
      {children}
    </RippleMenuList>
  );

  return (
    <RippleSelectContextProvider type="single" value={value} onChange={onChange}>
      <Flex direction="column" sx={boxSx}>
        <RippleMenu
          closeOnSelect
          closeOnBlur
          matchWidth={menuProps?.matchWidth ?? true}
          isOpen={menuProps?.isOpen}
          placement={menuProps?.placement ?? 'bottom'}
          size={size}
        >
          <RippleMenuButton ref={ref} as={RippleSelectButtonWithContext} {...formProps} {...menuButtonProps}>
            <RippleSelectLabel placeholder={placeholder} customLabel={customLabel} />
          </RippleMenuButton>
          {menuListProps?.appendUnderPortal ? <Portal>{MenuList}</Portal> : MenuList}
        </RippleMenu>

        {helperText && (
          <RippleTypography variant="body03" __css={styles.helperText} {...helperTextProps}>
            {helperText}
          </RippleTypography>
        )}
      </Flex>
    </RippleSelectContextProvider>
  );
}

export const RippleSingleSelect = forwardRef(RippleSingleSelectCore) as <T extends string>(
  props: RippleSingleSelectProps<T> & { ref?: React.ForwardedRef<any> },
) => React.JSX.Element;

export type RippleSingleSelectOptionProps = RippleSelectOptionProps;
export const RippleSingleSelectOption = RippleSelectOption;

export const RippleSingleSelectOptionGroup = RippleMenuGroup;

export const RippleSingleSelectOptionDivider = RippleMenuDivider;

export type UseRippleSingleSelectOptions<T extends string> = {
  defaultValue?: T | undefined;
  onChange?: (value: T | undefined) => void;
};

export type UseRippleSingleSelectReturn<T extends string> = {
  value: T | undefined;
  setValue: React.Dispatch<React.SetStateAction<T | undefined>>;
  selectProps: {
    value: T | undefined;
    onChange: (value: T | undefined) => void;
  };
};

export function useRippleSingleSelect<T extends string = string>({
  defaultValue,
  onChange,
}: UseRippleSingleSelectOptions<T> = {}): UseRippleSingleSelectReturn<T> {
  const [value, setValue] = useControllableState<T | undefined>({
    defaultValue,
    onChange,
  });

  return {
    value,
    setValue,
    selectProps: {
      value,
      onChange: setValue,
    },
  };
}
