import { useId } from 'react';

import { Icon } from '@chakra-ui/react';

export function RippleComputerEmptyGroupIcon(): React.JSX.Element {
  const radialGradient1 = useId();

  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none">
      <rect x="4.5" y="4.5" width={31} height={31} rx="3.5" fill="white" stroke={`url(#${radialGradient1})`} />
      <circle cx={20} cy={20} r={8} stroke="#B0B0B0" />
      <path d="M28 12L12 28" stroke="#B0B0B0" strokeLinecap="round" />
      <defs>
        <radialGradient
          id={radialGradient1}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(16)"
        >
          <stop stopOpacity="0.12" />
          <stop offset="0.828167" stopOpacity="0.12" />
        </radialGradient>
      </defs>
    </Icon>
  );
}
