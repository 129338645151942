import type { ComponentStyleConfig } from '@chakra-ui/react';

const styles: ComponentStyleConfig = {
  parts: ['tab', 'tablist', 'tabpanel', 'tabpanels'],
  baseStyle: {
    tablist: {
      height: '42px',
      borderBottom: '1px solid',
      borderBottomColor: 'neutral.100',
    },
    tab: {
      px: '16px',
      py: '12px',
      color: 'neutral.200',
      borderBottom: '2px solid',
      borderBottomColor: 'transparent',
      _selected: { bgColor: 'blue.10', borderBottomColor: 'blue.200', color: 'blue.200' },
      _disabled: {
        color: 'dark.10',
      },
      _hover: {
        bgColor: 'blue.0',
        borderBottomColor: 'blue.100',
        color: 'blue.100',
        _disabled: {
          cursor: 'not-allowed',
          bgColor: 'transparent',
          borderBottomColor: 'transparent',
          color: 'dark.10',
        },
      },
    },
    tabpanels: {
      marginTop: '16px',
    },
  },
  variants: {
    default: {},
    withinContainer: {
      tablist: {
        bgColor: '#FFF',
      },
      tab: {
        borderBottomColor: 'transparent',
        _disabled: {
          color: 'dark.40',
          bgColor: 'dark.10',
          borderBottom: 'none',
        },
        _hover: {
          bgColor: 'blue.0',
          borderBottomColor: 'blue.100',
          color: 'blue.100',
          _disabled: {
            cursor: 'not-allowed',
            color: 'dark.40',
            bgColor: 'dark.10',
            borderBottom: 'none',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

export const rippleTabs = styles;
