import { getRegion } from './region';

export const SERVER_ENV = process.env.NEXT_PUBLIC_SERVER_ENV || 'development';
const REGION = getRegion();

export const ALWAYS = true;
export const NOT_YET = false;
export const TEST_ONLY = process.env.NEXT_PUBLIC_IS_TEST === 'true';
export const LOCAL_ONLY = SERVER_ENV === 'local';
export const DEVELOPMENT_ONLY = SERVER_ENV === 'development';
export const PRODUCTION_ONLY = SERVER_ENV === 'production';

export const GLOBAL_ONLY = REGION === 'global';
export const EU_ONLY = REGION === 'eu';
export const OC_ONLY = REGION === 'oc';
