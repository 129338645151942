/**
 *  @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1176109287/User+Info+Query+API
 */
import axios from 'axios';

import { checkResponse } from '../../utils';
import { generateSchema } from './generateSchema';
import { QueryMap } from './types';

const method = 'POST';

const getUrl = () => '/api/web/v1/users/info_query';

const execute = <Q extends QueryMap>(query: Q) => {
  const schema = generateSchema(query);

  return checkResponse(axios.request({ method, url: getUrl(), data: query }), schema);
};

export const infoQueryService = {
  method,
  getUrl,
  execute,
} as const;
