import type { ComponentStyleConfig } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

const styles_deprecated: ComponentStyleConfig = {
  parts: ['button', 'helperText'],
  baseStyle: {
    button: {
      borderRadius: '4px',
      bgColor: 'white',
      border: '1px solid',
      borderColor: 'neutral.300',
      width: '100%',
      color: 'dark.90',
      _active: {
        bgColor: 'blue.0',
        borderColor: 'blue.100',
      },
      _disabled: {
        cursor: 'not-allowed',
        borderColor: 'dark.40',
        color: 'dark.40',
      },
      _hover: {
        _disabled: {
          bgColor: 'white',
        },
      },
      _invalid: {
        borderColor: 'red.200',
      },
      flexDirection: 'row',
      pl: '12px',
      pr: '10px',
    },
    helperText: {
      mt: '4px',
      color: 'neutral.300',
      _disabled: {
        color: 'dark.40',
      },
      _invalid: {
        color: 'red.100',
      },
    },
  },
  variants: {
    border: {
      button: {},
    },
    borderless: {
      button: {
        border: 'none',
        bgColor: 'neutral.40',
        _disabled: {
          bgColor: 'dark.10',
          color: 'dark.60',
        },
        _hover: {
          _disabled: {
            bgColor: 'dark.10',
          },
        },
        _invalid: {
          bgColor: 'red.10',
        },
      },
    },
    ghost: {
      button: {},
    },
  },
  sizes: {
    lg: {
      button: {
        h: '48px',
      },
    },
    md: {
      button: {
        h: '44px',
      },
    },
    sm: {
      button: {
        h: '40px',
      },
    },
    xs: {
      button: {
        h: '32px',
      },
    },
  },
  defaultProps: {
    variant: 'border',
    size: 'sm',
  },
};

const styles: ComponentStyleConfig = {
  parts: ['button', 'helperText', 'item'],
  baseStyle: {
    button: {
      borderRadius: '4px',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '4px',
      px: '11px',
      _disabled: {
        cursor: 'not-allowed',
        color: 'dark.40',
      },
    },
    helperText: {
      mt: '4px',
      color: 'neutral.300',
      _disabled: {
        color: 'dark.40',
      },
      _invalid: {
        color: 'red.100',
      },
    },
  },
  variants: {
    border: {
      button: {
        border: '1px solid',
        borderColor: 'neutral.300',
        bgColor: 'neutral.400_0',
        color: 'dark.90',
        _hover: {
          bgColor: 'neutral.400_4',
        },
        _active: {
          bgColor: 'neutral.400_8',
          _disabled: {
            bgColor: 'neutral.400_4',
          },
        },
        _disabled: {
          borderColor: 'dark.40',
          bgColor: 'neutral.400_4',
        },
        _invalid: {
          borderColor: 'red.80',
          bgColor: 'red.100_8',
        },
      },
    },
    borderless: {
      button: {
        border: '1px solid',
        borderColor: 'neutral.400_0',
        bgColor: 'neutral.400_4',
        color: 'dark.90',
        _hover: {
          bgColor: 'neutral.400_8',
          _disabled: {
            bgColor: 'neutral.400_4',
          },
        },
        _active: {
          bgColor: 'neutral.400_16',
          _disabled: {
            bgColor: 'neutral.400_4',
          },
        },
        _disabled: {
          bgColor: 'neutral.400_4',
        },
        _invalid: {
          bgColor: 'red.100_8',
        },
      },
    },
    ghost: {
      button: {
        maxW: '320px',
        border: '1px solid',
        borderColor: 'neutral.400_0',
        bgColor: 'neutral.400_0',
        color: 'neutral.400',
        _hover: {
          bgColor: 'neutral.400_8',
          _disabled: {
            bgColor: 'neutral.400_0',
          },
        },
        _active: {
          bgColor: 'neutral.400_16',
          _disabled: {
            bgColor: 'neutral.400_0',
          },
        },
        _disabled: {
          color: 'dark.40',
          bgColor: 'neutral.400_0',
        },
      },
    },
  },
  sizes: {
    lg: {
      button: {
        h: '48px',
      },
    },
    md: {
      button: {
        h: '44px',
      },
    },
    sm: {
      button: {
        h: '40px',
      },
    },
    xs: {
      button: {
        h: '32px',
      },
    },
  },
  defaultProps: {
    variant: 'border',
    size: 'sm',
  },
};

export const rippleSelect = featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? styles : styles_deprecated;
