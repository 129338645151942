import { ChakraTheme } from '@chakra-ui/react';

const styles: ChakraTheme['styles'] = {
  global: {
    body: {
      fontFamily: "'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
  },
};

export default styles;
