import { Icon } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowRight16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
        <path d="M6 12L10 8L6 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      ) : (
        <path
          d="M5.21809 3.6234C4.87383 3.19155 4.94483 2.56238 5.37669 2.21811C5.80854 1.87385 6.43772 1.94485 6.78198 2.37671L10.782 7.3944C11.0735 7.76013 11.0726 8.27908 10.7798 8.6438L6.77982 13.6261C6.43407 14.0568 5.80466 14.1256 5.37399 13.7798C4.94333 13.4341 4.8745 12.8047 5.22025 12.374L8.71941 8.01554L5.21809 3.6234Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
}
