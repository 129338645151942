import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useAtomValue } from 'jotai';
import Cookie from 'js-cookie';

import { COOKIE_KEY_PCP_SOS_TEAM_ID, COOKIE_KEY_PCP_STA_TEAM_ID, COOKIE_KEY_PCP_STB_TEAM_ID } from '@/authentication';
import { basicProfileAtom } from '@/models/BasicProfile';
import { useTeamInformation } from '@/models/TeamInformation';

export const RudderStackTracking = ({ children }: { children: React.ReactNode }) => {
  const { team_id, teamKind } = useTeamInformation();
  const basicProfile = useAtomValue(basicProfileAtom);
  const { email } = basicProfile.profile;
  const teamInfo = basicProfile.infoQuery?.[teamKind]?.team_info;
  const sosTeamId = Cookie.get(COOKIE_KEY_PCP_SOS_TEAM_ID);
  const staTeamId = Cookie.get(COOKIE_KEY_PCP_STA_TEAM_ID);
  const stbTeamId = Cookie.get(COOKIE_KEY_PCP_STB_TEAM_ID);

  useEffect(() => {
    try {
      if (typeof window !== 'undefined' && window.rudderStackTracking) {
        window.rudderStackTracking.identify(email, {
          email,
          team_id: team_id,
          feature_id: teamInfo?.feature_id,
          feature_capacity: teamInfo?.feature_capacity,
          sos_team_id: sosTeamId,
          sta_team_id: staTeamId,
          stb_team_id: stbTeamId,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [team_id, teamKind, email, basicProfile.infoQuery, sosTeamId, staTeamId, stbTeamId, teamInfo?.feature_id, teamInfo?.feature_capacity]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.rudderStackTracking) {
      window.rudderStackTracking.trackPageEvents();
      window.rudderStackTracking.fixOptimizelyTracking();
    }
  }, []);

  return <>{children}</>;
};
