import { Icon } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowDown16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" color={color}>
      {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.46967 5.46967C3.76256 5.17678 4.23744 5.17678 4.53033 5.46967L8 8.93934L11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967C12.8232 5.76256 12.8232 6.23744 12.5303 6.53033L8.53033 10.5303C8.38968 10.671 8.19891 10.75 8 10.75C7.80109 10.75 7.61032 10.671 7.46967 10.5303L3.46967 6.53033C3.17678 6.23744 3.17678 5.76256 3.46967 5.46967Z"
          fill="currentColor"
        />
      ) : (
        <path
          d="M12.3767 5.21809C12.8085 4.87383 13.4377 4.94483 13.782 5.37669C14.1262 5.80854 14.0552 6.43772 13.6234 6.78198L8.60569 10.782C8.23996 11.0735 7.72101 11.0726 7.35629 10.7798L2.37399 6.77982C1.94333 6.43407 1.87449 5.80466 2.22025 5.37399C2.566 4.94333 3.19542 4.8745 3.62608 5.22025L7.98455 8.71941L12.3767 5.21809Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
}
