import { Flex, Portal, forwardRef, useControllableState, useFormControl, useMultiStyleConfig } from '@chakra-ui/react';

import { RippleMenu, RippleMenuButton, RippleMenuDivider, RippleMenuGroup, RippleMenuList } from '../RippleMenu';
import { RippleTypography } from '../RippleTypography';
import { RippleSelectContextProvider, RippleSelectLabel, RippleSelectOptionWithCheckbox } from './RippleSelect';
import type { RippleSelectOptionProps, RippleSelectProps } from './RippleSelect';
import { RippleSelectButtonWithContext } from './RippleSelectButton';

export type RippleMultipleSelectProps<T extends string = string> = RippleSelectProps & {
  value: Array<T> | undefined;
  onChange: (value: Array<T> | undefined) => void;
  onCloseMenuList?: () => void;
};

function RippleMultipleSelectCore(props: RippleMultipleSelectProps, ref: React.ForwardedRef<any>): React.JSX.Element {
  const { customLabel, onCloseMenuList, boxSx, menuListSx, menuListProps, menuProps, ...selectProps } = props;
  const { children, value, placeholder, onChange, variant = 'border', size = 'sm', helperText, ...otherProps } = selectProps;
  const styles = useMultiStyleConfig('rippleSelect', { variant, size });
  const formProps = useFormControl<HTMLButtonElement>(selectProps);
  const { id: _id, onFocus: _onFocus, onBlur: _onBlur, ...helperTextProps } = formProps;

  const MenuList = (
    <RippleMenuList zIndex="dropdown" position="relative" sx={menuListSx}>
      {children}
    </RippleMenuList>
  );

  return (
    <RippleSelectContextProvider type="multiple" value={value} onChange={onChange}>
      <Flex direction="column" sx={boxSx}>
        <RippleMenu
          closeOnSelect={false}
          closeOnBlur
          onClose={onCloseMenuList}
          matchWidth={menuProps?.matchWidth ?? true}
          isOpen={menuProps?.isOpen}
          placement={menuProps?.placement ?? 'bottom'}
          size={size}
        >
          <RippleMenuButton
            ref={ref}
            as={RippleSelectButtonWithContext}
            size={size}
            isShowCount={value && Boolean(value.length > 0)}
            count={value?.length}
            {...formProps}
            {...otherProps}
          >
            <RippleSelectLabel placeholder={placeholder} customLabel={customLabel} />
          </RippleMenuButton>
          {menuListProps?.appendUnderPortal ? <Portal>{MenuList}</Portal> : MenuList}
        </RippleMenu>
        {helperText && (
          <RippleTypography variant="body03" __css={styles.helperText} {...helperTextProps}>
            {helperText}
          </RippleTypography>
        )}
      </Flex>
    </RippleSelectContextProvider>
  );
}

export const RippleMultipleSelect = forwardRef(RippleMultipleSelectCore) as <T extends string>(
  props: RippleMultipleSelectProps<T> & { ref?: React.ForwardedRef<any> },
) => React.JSX.Element;

export type RippleMultipleSelectOptionProps = RippleSelectOptionProps;
export const RippleMultipleSelectOption = RippleSelectOptionWithCheckbox;

export const RippleMultipleSelectOptionGroup = RippleMenuGroup;

export const RippleMultipleSelectOptionDivider = RippleMenuDivider;

export type UseRippleMultipleSelectOptions<T extends string> = {
  defaultValue?: Array<T> | undefined;
  onChange?: (value: Array<T> | undefined) => void;
};

export type UseRippleMultipleSelectReturn<T extends string> = {
  value: Array<T> | undefined;
  setValue: React.Dispatch<React.SetStateAction<Array<T> | undefined>>;
  selectProps: {
    value: Array<T> | undefined;
    onChange: React.Dispatch<React.SetStateAction<Array<T> | undefined>>;
  };
};

export function useRippleMultipleSelect<T extends string = string>({
  defaultValue,
  onChange,
}: UseRippleMultipleSelectOptions<T> = {}): UseRippleMultipleSelectReturn<T> {
  const [value, setValue] = useControllableState<Array<T> | undefined>({
    defaultValue,
    onChange,
  });

  return {
    value,
    setValue,
    selectProps: {
      value,
      onChange: setValue,
    },
  };
}
