import { SERVER_ENV } from './condition';
import { type Region } from './region';

/** CDN DOC: https://splashtop.atlassian.net/wiki/spaces/CS/pages/834306327/CDN+hosted+font+files */
export const ASSETS_CDN_MAPPING: Record<Region, Record<typeof SERVER_ENV, string>> = {
  global: {
    production: 'https://asset-g3.splashtop.com',
    development: 'https://asset-g3.aws-rd.splashtop.com',
  },
  eu: {
    production: 'https://asset-g3.splashtop.eu',
    development: 'https://asset-g3.aws-rd.splashtop.eu',
  },
  oc: {
    production: 'https://asset-g3.splashtop.nr',
    development: 'https://asset-g3.aws-rd.splashtop.nr',
  },
};

export const HOST_MAPPING: Record<Region, Record<typeof SERVER_ENV, string>> = {
  global: {
    production: 'https://my.splashtop.com',
    development: 'https://my.aws-rd.splashtop.com',
    local: 'https://my.aws-rd.splashtop.com',
  },
  eu: {
    production: 'https://my.splashtop.eu',
    development: 'https://my.aws-rd.splashtop.eu',
    local: 'https://my.aws-rd.splashtop.eu',
  },
  oc: {
    production: 'https://my.splashtop.nr',
    development: 'https://my.aws-rd.splashtop.nr',
    local: 'https://my.aws-rd.splashtop.nr',
  },
};
