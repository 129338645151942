import { ReactNode, useId } from 'react';

import {
  Box,
  BoxProps,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuItemProps,
  MenuList,
  MenuListProps,
  MenuOptionGroup,
  MenuProps,
  SwitchProps,
  forwardRef,
} from '@chakra-ui/react';
import type { MenuButtonProps, MenuGroupProps, SystemStyleObject } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import { RippleButton, RippleButtonProps } from '../RippleButton';
import { RippleCheckbox } from '../RippleCheckbox';
import { RippleArrowDown16, RippleArrowUp16, RippleOK } from '../RippleIcon';
import { RippleIconButton, RippleIconButtonProps } from '../RippleIconButton';
import { RippleStrong } from '../RippleStrong';
import { RippleSwitch } from '../RippleSwitch';
import { RippleBodyText02, RippleButtonText } from '../RippleText';
import { RippleTypography } from '../RippleTypography';

export type RippleMenuProps = MenuProps & { isDisabled?: boolean; size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs' };
export const RippleMenu = ({ isDisabled, isOpen, ...otherProps }: RippleMenuProps) => (
  <Menu isOpen={isDisabled ? false : isOpen} {...otherProps} />
);

export type RippleMenuButtonProps = MenuButtonProps;
export const RippleMenuButton = MenuButton;

export type RippleMenuButtonGhostProps = MenuButtonProps & { isOpen: boolean };

/**
 * @deprecated Should be replaced to RippleSelectButton variant=ghost
 */
export const RippleMenuButtonGhost = forwardRef<RippleMenuButtonGhostProps, 'button'>(({ isOpen, children, ...otherProps }, ref) => {
  const defaultColor = 'neutral.300';
  const activeColor = 'blue.200';
  const hoverColor = 'blue.100';

  return (
    <RippleMenuButton
      border={0}
      height="initial"
      borderRadius="4px"
      padding="7px 12px"
      backgroundColor={isOpen ? 'blue.20' : 'transparent'}
      _hover={{
        bg: 'blue.10',
        '.buttonText': {
          color: isOpen ? activeColor : hoverColor,
        },
        svg: {
          color: isOpen ? activeColor : hoverColor,
        },
      }}
      ref={ref}
      {...otherProps}
    >
      <Flex justifyContent="center" alignItems="center">
        <RippleButtonText className="buttonText" color={isOpen ? activeColor : defaultColor} mr="4px">
          {children}
        </RippleButtonText>
        {isOpen ? <RippleArrowUp16 color={isOpen ? activeColor : defaultColor} /> : <RippleArrowDown16 color={defaultColor} />}
      </Flex>
    </RippleMenuButton>
  );
});

export const RippleMenuGeneralButton = forwardRef<MenuButtonProps & RippleButtonProps, 'button'>((props, ref) => {
  return <RippleMenuButton as={RippleButton} ref={ref} {...props} />;
});

export const RippleMenuIconButton = forwardRef<MenuButtonProps & RippleIconButtonProps, 'button'>((props, ref) => {
  return <RippleMenuButton as={RippleIconButton} ref={ref} {...props} />;
});

export type RippleMenuListProps = MenuListProps;
export const RippleMenuList = forwardRef<MenuListProps, 'div'>((props, ref) => {
  return <MenuList overflowY="auto" ref={ref} {...props} />;
});

const RippleSelectedIconSection = ({ isSelected }: { isSelected?: boolean }) => {
  return (
    <Flex
      className="ripple-menu__item__check"
      w="24px"
      h="24px"
      ml={featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? '0px' : '8px'}
      flexShrink={0}
    >
      {isSelected ? <RippleOK color="blue.100" /> : null}
    </Flex>
  );
};

export type RippleMenuItemProps = MenuItemProps & { isSelected?: boolean };

/**
 * TODO: see PCP-4068
 */
export const RippleMenuItem = forwardRef<RippleMenuItemProps, 'button'>(({ children, isSelected, icon, ...otherProps }, ref) => {
  const py = computePaddingY();

  function computePaddingY(): string {
    const hasIcon = Boolean(icon);

    if (hasIcon) return '8px';
    if (isSelected) return '4px';
    return '6px';
  }

  return (
    <MenuItem className="ripple-menu__item" ref={ref} py={py} {...otherProps}>
      {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
        <>
          {icon && (
            <Center className="ripple-menu__item__icon" height="24px" flexShrink={0}>
              {icon}
            </Center>
          )}
          {isSelected ? (
            <RippleStrong className="ripple-menu__item__label ripple-menu__item__label--selected" as="div" variant="strong02" flex={1}>
              {children}
            </RippleStrong>
          ) : (
            <RippleTypography className="ripple-menu__item__label" as="div" variant="body02" flex={1}>
              {children}
            </RippleTypography>
          )}
          <>{isSelected !== undefined && <RippleSelectedIconSection isSelected={isSelected} />}</>
        </>
      ) : (
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          {icon && (
            <Center className="ripple-menu__item__icon" height="24px" mr="8px" flexShrink={0}>
              {icon}
            </Center>
          )}
          {isSelected ? (
            <RippleStrong
              className="ripple-menu__item__label ripple-menu__item__label--selected"
              as="div"
              variant="strong02"
              isTruncated
              flex={1}
            >
              {children}
            </RippleStrong>
          ) : (
            <RippleTypography className="ripple-menu__item__label" as="div" variant="body02" isTruncated flex={1}>
              {children}
            </RippleTypography>
          )}
          <RippleSelectedIconSection isSelected={isSelected} />
        </Flex>
      )}
    </MenuItem>
  );
});

export const RippleMenuItemTextOnly = forwardRef<MenuItemProps & { sxText?: SystemStyleObject }, 'button'>(
  ({ children, sxText, ...otherProps }, ref) => {
    return (
      <>
        {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
          <MenuItem className="ripple-menu__item" ref={ref} {...otherProps}>
            <RippleBodyText02 className="ripple-menu__item__label" sx={sxText}>
              {children}
            </RippleBodyText02>
          </MenuItem>
        ) : (
          <MenuItem className="ripple-menu__item" px="12px" ref={ref} {...otherProps}>
            <RippleBodyText02 className="ripple-menu__item__label" sx={sxText}>
              {children}
            </RippleBodyText02>
          </MenuItem>
        )}
      </>
    );
  },
);

export const RippleMenuItemWithSwitch = forwardRef<SwitchProps, 'button'>(({ children, ...props }, ref) => {
  const uuid = useId();
  return (
    <>
      {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
        <MenuItem as={FormControl} cursor="pointer" py="0" ref={ref} isDisabled={props.isDisabled}>
          <FormLabel htmlFor={uuid} as={FormLabel} cursor="inherit" w="100%" m="0">
            <RippleBodyText02 py="6px">{children}</RippleBodyText02>
          </FormLabel>
          <RippleSwitch id={uuid} {...props} />
        </MenuItem>
      ) : (
        <MenuItem
          as={FormControl}
          cursor="pointer"
          ref={ref}
          display="flex"
          justifyContent="space-between"
          p="0"
          isDisabled={props.isDisabled}
        >
          <FormLabel htmlFor={uuid} as={FormLabel} cursor="inherit" w="100%" m="0" pl="12px" py="6px">
            <RippleBodyText02 mr="8px">{children}</RippleBodyText02>
          </FormLabel>
          <RippleSwitch id={uuid} pr="12px" {...props} />
        </MenuItem>
      )}
    </>
  );
});

/**
 * @deprecated Should be removed after `RippleMenuItemSingleSelect` and `RippleMenuItemSingleSelectCustom` is deleted.
 */
const RippleMenuItemSingleSelectItem = ({ isSelected, children }: { isSelected: boolean; children: ReactNode }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" width="100%">
      {isSelected ? <RippleButtonText>{children}</RippleButtonText> : <RippleBodyText02>{children}</RippleBodyText02>}
      <RippleSelectedIconSection isSelected={isSelected} />
    </Flex>
  );
};

/**
 * @deprecated Use in 16 files, should be replace by RippleSingleSelectOption
 */
export const RippleMenuItemSingleSelect = forwardRef<MenuItemProps & { isSelected: boolean }, 'button'>(
  ({ isSelected, children, ...otherProps }, ref) => {
    return (
      <MenuItem px="12px" bg={isSelected ? 'blue.10' : 'white'} ref={ref} {...otherProps}>
        <RippleMenuItemSingleSelectItem isSelected={isSelected}>{children}</RippleMenuItemSingleSelectItem>
      </MenuItem>
    );
  },
);

/**
 * @deprecated Only `src/design/RippleComputer/RippleComputerGroupDropdown.tsx` using, should be refactored.
 */
export const RippleMenuItemSingleSelectCustom = forwardRef<BoxProps & { isSelected: boolean; CustomComponent: typeof Box }, 'button'>(
  ({ isSelected, CustomComponent, children, ...otherProps }, ref) => {
    return (
      <CustomComponent
        as="button"
        width="100%"
        px="12px"
        py="6px"
        bg={isSelected ? 'blue.10' : 'white'}
        _hover={{ bg: 'blue.0' }}
        ref={ref}
        {...otherProps}
      >
        <RippleMenuItemSingleSelectItem isSelected={isSelected}>{children}</RippleMenuItemSingleSelectItem>
      </CustomComponent>
    );
  },
);

export const RippleMenuItemOption = MenuItemOption;

export type RippleMenuGroupProps = MenuGroupProps & { emphasized?: boolean };
export const RippleMenuGroup = forwardRef<RippleMenuGroupProps, 'div'>(
  ({ emphasized, title, children, className = 'chakra-menu__group__title', ...otherProps }, ref) => {
    // NOTE: Due to styling, the title element refers to `MenuGroup` source code, so we pass props to the title element
    return (
      <MenuGroup ref={ref}>
        {title && (
          <RippleTypography
            className={className}
            variant="heading09"
            color={emphasized ? 'blue.100' : 'neutral.100'}
            px="12px"
            py="5px"
            {...otherProps}
          >
            {title}
          </RippleTypography>
        )}

        {children}
      </MenuGroup>
    );
  },
);

export const RippleMenuOptionGroup = MenuOptionGroup;
export const RippleMenuDivider = MenuDivider;

/**
 * @deprecated Only `src/components/SimpleTable/Actions/FilterMenus/index.tsx` using, should be refactored.
 */
export const RippleMenuItemMultipleSelect = forwardRef<
  MenuItemProps & { isSelected: boolean; onClick: React.MouseEventHandler<HTMLDivElement> },
  'button'
>(({ isSelected, onClick, children, ...otherProps }, ref) => {
  return (
    <MenuItem ref={ref} onClick={onClick} {...otherProps}>
      <RippleCheckbox isReadOnly isChecked={isSelected} onClick={onClick}>
        <RippleTypography as="span" variant="body02">
          {children}
        </RippleTypography>
      </RippleCheckbox>
    </MenuItem>
  );
});
