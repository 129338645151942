import { ALWAYS, DEVELOPMENT_ONLY, LOCAL_ONLY, NOT_YET, OC_ONLY, SERVER_ENV } from './condition';
import { ASSETS_CDN_MAPPING, HOST_MAPPING } from './constants';
import { featureToggleControl } from './featureToggleControl';
import { createParameterControl } from './parameterControl';
import { getRegion } from './region';

const REGION = getRegion();

export const featureControl = featureToggleControl({
  newSettingsPage: LOCAL_ONLY,
  newTeamSettingsFailoverForTesting: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfFileTransfer: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfCopyPaster: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__sso__idpType__splashtopSecureOverlay: LOCAL_ONLY || DEVELOPMENT_ONLY,
  CF_355__computer_list_UI_renewal__phase_2: NOT_YET,
  PCP_6__dashboard__show_reboot_required: LOCAL_ONLY,
  /** Remove those two toggle together, PAGE_antivirus_threat_logs__redirect is for QA testing before antivirus release */
  PAGE_antivirus_threat_logs: ALWAYS,
  PAGE_antivirus_threat_logs__redirect: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** Remove those two toggle together end */
  CLE_53__Computer_Device_Icon_Change: NOT_YET, // NOTE: Need to wait for SRC app to implement
  // Policy start
  PCP_2648__Policy_software_patch_show_mac_beqa_only: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2377__Policy_os_patch_show_mac_beqa_only: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3546__Policy_preference_setting_operator_should_be_minus_when_uncheck: ALWAYS,
  PCP_3517__Policy_streamer_settings_the_enabled_can_be_revert: ALWAYS,
  PCP_3620__Policy_create_items_should_have_required_field_if_parent_has_no_value: ALWAYS,
  // Policy end
  PCP_1250__SystemToolsSession__DetailsIcon: ALWAYS,
  PCP_1537__EndpointSecurity__ComputerListAPI_TokenMethod: ALWAYS,
  PCP_1850_Zendesk_Widget_Instrumentality: ALWAYS,
  PCP_1713__GetStartedPage: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2281__Impact_Advocate_Widget: ALWAYS,
  PCP_2888_Zendesk_GroupContext: ALWAYS,
  PCP_2807__PreferencePolicy_Expiration: ALWAYS,
  PCP_2871__Inventory_Export_Disabled__TeamExpiration: ALWAYS,
  PCP_3527__EMM_policy__redirect_invited_user: ALWAYS,
  PCP_3403__EMM_policy__create_default_policy_when_assign: ALWAYS,
  PCP_3505__Implement_Simple_Group_List: ALWAYS,
  PCP_4105__RudderStack_add_team_info: ALWAYS,
  PCP_3641__TeamStatus_Condition: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2430__TeamSettings_SOS_invitation_template: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3566__TeamSettings_SOS_invitation_template__allow_custom: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3670__Policy__overridden__hardcode_enable_to_true: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** Colin start */
  PCP_3625__URI_Launch_Support_Special_Character: ALWAYS,
  PCP_3743__Export_Selected_Users: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_4041__Select_All: ALWAYS,
  /** Colin end */
  PCP_3477__SOS_Customization__ImageUploader: ALWAYS,
  PCP_3338__PCP_3339__SOS: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** --- Uni start --- */
  PCP_3052__Dashboard__Software_opportunities: LOCAL_ONLY || DEVELOPMENT_ONLY || OC_ONLY,
  PCP_3798__Dashboard__reduce_memory_usage: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3747__Remove_legacy_reminder: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2852__Remove_osasno_script_on_session_recording: ALWAYS,
  PCP_4181__Policy_list__performance_improvement: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_4182__Policy_assigning__collapse_group_by_default: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** --- Uni end --- */
  PCP_3054__Add_SRS_Distribution_In_OS_Overview_Widget: ALWAYS,
  PCP_3760__VoiceCall_ActiveSessions_SOS14: ALWAYS,
  PCP_2740__TeamSettings_AllowVersionControl_android: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3236_Keep_loading_indicator_on_the_screen_center: ALWAYS,
  PCP_3750__System_Tools_Sessions: ALWAYS,
  PCP_3801__FileUploader: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3529__Unified_product_access_sos_page: ALWAYS,
  PCP_3679__Should_remove_group_type_option_for_unified_access_solo: ALWAYS,
  PCP_3896__SOS_Customization_ShareIframe: LOCAL_ONLY || DEVELOPMENT_ONLY || OC_ONLY,
  PCP_3503__Ops_simple_mode_implement: ALWAYS,
  PCP_3795__Add_text_and_hyperlink_to_the_dashboard_header: ALWAYS,
  PCP_3954__Redesign_Ripple_Select_and_Menu: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3751__Alignment_SessionType: ALWAYS,
  PCP_3951__SystemToolsSessions__Permission_License: ALWAYS,
  PCP_3618__ServiceDesk__ChatWidget: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3282__SOS_Customization__fix_permission_check: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_4077_LOGIN_STREAMER: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_4092__Add_info_query_data_into_basicProfileAtom: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1830__web_client_watermark: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_4129__VerifiedEmailRequirement__SOS_Customization: ALWAYS,
  PCP_2213__Endpoint_security_update_virus_definition: LOCAL_ONLY || DEVELOPMENT_ONLY,
});

export const parameterControl = createParameterControl({
  logServiceUrl: process.env.NEXT_PUBLIC_LOG_SERVICE_URL as string,
  fontDomain: `${ASSETS_CDN_MAPPING[REGION][SERVER_ENV] || ASSETS_CDN_MAPPING.global.production}/fonts`,
  host: `${HOST_MAPPING[REGION][SERVER_ENV] || HOST_MAPPING.global.production}`,
});
