import { Icon } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleArrowLeft16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.46967 8.53033C5.17678 8.23744 5.17678 7.76256 5.46967 7.46967L9.46967 3.46967C9.76256 3.17678 10.2374 3.17678 10.5303 3.46967C10.8232 3.76256 10.8232 4.23744 10.5303 4.53033L7.06066 8L10.5303 11.4697C10.8232 11.7626 10.8232 12.2374 10.5303 12.5303C10.2374 12.8232 9.76256 12.8232 9.46967 12.5303L5.46967 8.53033Z"
          fill="currentColor"
        />
      ) : (
        <path
          d="M10.7819 12.3767C11.1262 12.8085 11.0552 13.4377 10.6233 13.782C10.1915 14.1262 9.56228 14.0552 9.21802 13.6234L5.21802 8.60567C4.92647 8.23995 4.92737 7.72099 5.22018 7.35628L9.22018 2.37398C9.56593 1.94331 10.1953 1.87448 10.626 2.22023C11.0567 2.56599 11.1255 3.1954 10.7797 3.62607L7.28059 7.98453L10.7819 12.3767Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
}
