import { z } from 'zod';

import { teamKindSchema } from '@/services/common/types';

import { getSeatPermissionSchema } from './seat_permissions/schemas';
import { teamKindResultBaseSchema } from './teamKindResultBase';
import { getTeamInfoSchema } from './team_info/schemas';
import { getTeamMemberInfoSchema } from './team_member_info/schemas';
import { getTeamMemberPermissionSchema } from './team_member_permissions/schemas';
import { getTeamPermissionSchema } from './team_permissions/schemas';
import type { InfoQueryResult, QueryMap } from './types';

export function generateSchema<Q extends QueryMap>(query: Q): z.ZodType<InfoQueryResult<Q>> {
  const teamInfoSchema = getTeamInfoSchema(query);
  const seatPermissionSchema = getSeatPermissionSchema(query);
  const teamPermissionSchema = getTeamPermissionSchema(query);
  const teamMemberPermissionSchema = getTeamMemberPermissionSchema(query);
  const teamMemberInfoSchema = getTeamMemberInfoSchema(query);

  const teamKindResultSchema = teamKindResultBaseSchema
    .merge(teamInfoSchema)
    .merge(seatPermissionSchema)
    .merge(teamPermissionSchema)
    .merge(teamMemberPermissionSchema)
    .merge(teamMemberInfoSchema);

  // @ts-ignore - Type instantiation is excessively deep and possibly infinite.ts(2589)
  return z.record(teamKindSchema, teamKindResultSchema).refine((data) => {
    const teamKindResults: Array<any> = Object.values(data);
    return teamKindResults.length > 0;
  }, 'No team kind results found');
}
