import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleReady16({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8.39624C15 12.2622 11.866 15.3962 8 15.3962C4.13401 15.3962 1 12.2622 1 8.39624C1 4.53025 4.13401 1.39624 8 1.39624C11.866 1.39624 15 4.53025 15 8.39624ZM11.5657 7.46193C11.8781 7.14951 11.8781 6.64298 11.5657 6.33056C11.2533 6.01814 10.7467 6.01814 10.4343 6.33055L7 9.76486L5.56568 8.33055C5.25326 8.01814 4.74673 8.01814 4.43431 8.33056C4.12189 8.64298 4.1219 9.14951 4.43432 9.46193L6.43431 11.4619C6.74673 11.7743 7.25326 11.7743 7.56568 11.4619L11.5657 7.46193Z"
        fill="currentColor"
      />
    </Icon>
  );
}
