import axios, { AxiosInstance } from 'axios';

import { TeamKind } from '@/models/TeamInformation';
import type {
  AlertLogsUnread,
  AlertLogsUnreadData,
  NewVersionData,
  Profile,
  PublicInformationData,
  QuickSetupInfoData,
  ResponseData,
  ResponseErrorReason,
  SsoMethods,
  SsoMethodsData,
  SystemInfo,
  Teams,
  UserBasicProfileData,
  UserComputersWithCustomizedFields,
} from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';

export const ACCESS_TOKEN = '/api/web/v1/auth/access_token';
const USER_BASIC_PROFILE = '/api/web/v1/users/basic_profile';
const PERMISSION_QUERY = '/api/web/v1/users/permissions_query';
export const INFO_QUERY = '/api/web/v1/users/info_query';
const ALERT_LOGS_UNREAD_COUNT = '/api/web/v1/users/alert_logs/unread_count';
const ALERT_LOGS_UNREAD = '/api/web/v1/users/alert_logs/unread';
const ALERT_LOGS_ACKNOWLEDGE = '/api/web/v1/users/alert_logs/acknowledge_logs';
const ALERT_LOGS_ACKNOWLEDGE_ALL = '/api/web/v1/users/alert_logs/acknowledge_all';
const SOS_TEAMS_PREFIX = '/api/web/v1/sos/teams';
const SSO_METHODS = '/sso_methods';
const PRICES = '/api/web/v1/prices';
const QUICK_SETUP_INFO = '/api/web/v1/users/quick_setup_info';
export const USER_COMPUTERS = '/api/web/v1/users/computers';
const USER_CLIENTS = '/api/web/v1/users/clients';

type GetUserBasicProfileParams = {
  requestInstance?: AxiosInstance;
};

/** @deprecated Get data from `infoQueryService` instead */
function getUserBasicProfile(options: GetUserBasicProfileParams = {}) {
  return new Promise<{ profile: Profile; teams: Teams; system_info: SystemInfo }>((resolve, reject) => {
    (options?.requestInstance ?? axios)
      .get<ResponseData & UserBasicProfileData>(USER_BASIC_PROFILE)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function queryPermissions(
  product: keyof Teams,
  {
    seat_permissions = [],
    team_permissions = [],
    team_member_permissions = [],
  }: { seat_permissions?: Array<string>; team_permissions?: Array<string>; team_member_permissions?: Array<string> },
) {
  return new Promise<{
    seat_permissions: Record<string, boolean>;
    team_permissions: Record<string, boolean>;
    team_member_permissions: Record<string, boolean>;
  }>((resolve, reject) => {
    axios
      .post<
        ResponseData & {
          data: {
            seat_permissions: Record<string, boolean>;
            team_permissions: Record<string, boolean>;
            team_member_permissions: Record<string, boolean>;
          };
        }
      >(PERMISSION_QUERY, { product, seat_permissions, team_permissions, team_member_permissions })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getAlertLogsUnreadCount(lastReadAt: string) {
  return new Promise<number>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { count: number } }>(`${ALERT_LOGS_UNREAD_COUNT}?last_read_at=${lastReadAt}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          const { count } = data;
          resolve(count);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getAlertLogsUnread(lastReadAt: string) {
  return new Promise<Array<AlertLogsUnread>>((resolve, reject) => {
    axios
      .get<ResponseData & AlertLogsUnreadData>(`${ALERT_LOGS_UNREAD}?last_read_at=${lastReadAt}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function acknowledgeAlertLog(logIds: number | Array<number>) {
  let ids: Array<number> = [];
  if (typeof logIds === 'number') {
    ids.push(logIds);
  } else {
    ids = logIds;
  }
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData & ResponseErrorReason>(ALERT_LOGS_ACKNOWLEDGE, { ids })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function acknowledgeAllAlertLogs() {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData & ResponseErrorReason>(ALERT_LOGS_ACKNOWLEDGE_ALL)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getSSOMethods(teamId?: number) {
  return new Promise<Array<SsoMethods>>((resolve, reject) => {
    if (!teamId) {
      reject('does not provide team id');
    } else {
      const ssoUrl = `${SOS_TEAMS_PREFIX}/${teamId}${SSO_METHODS}`;
      axios
        .get<ResponseData & SsoMethodsData>(ssoUrl)
        .then((res) => {
          const {
            data: { result, messages, data },
          } = res;

          if (isSuccess(result)) {
            resolve(data);
          } else {
            reject({ statusCode: result, messages, errorReason: data });
          }
        })
        .catch((error) => {
          const statusCode = error.response ? error.response.status : 9487;
          reject({ statusCode, messages: [], errorReason: {} });
        });
    }
  });
}

function getSBADownloadUrl(platform: string) {
  return `/src/${platform}`;
}

function getCurrency() {
  return new Promise<[currency: string, currencyLocales: string]>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { profile_items: { currency: string; currency_locale: string } } }>(INFO_QUERY, {
        profile_items: ['currency', 'currency_locale'],
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve([data?.profile_items?.currency ?? 'USD', data?.profile_items?.currency_locale ?? 'en-US']);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getPrices() {
  return new Promise<PublicInformationData['data']['prices']>((resolve, reject) => {
    axios
      .get<ResponseData & PublicInformationData>(PRICES)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data.prices);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getSRSDownloadLink() {
  return new Promise<string>((resolve, reject) => {
    axios
      .get<ResponseData & { data: QuickSetupInfoData }>(QUICK_SETUP_INFO)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data.srs_download_link);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `getQuickSetupInfoService` instead.
 */
function getQuickSetupInfo() {
  return new Promise<QuickSetupInfoData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: QuickSetupInfoData }>(QUICK_SETUP_INFO)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getUserComputersWithCustomizedFields(options: { canGetNote: boolean }) {
  const customizedFields = [
    'email',
    'group_id',
    'last_online',
    'pubip',
    'local_ip',
    'version',
    'last_session',
    'online_status',
    'is_device_owner',
    'deployed',
  ];

  if (options.canGetNote) {
    customizedFields.push('note');
  }

  return new Promise<Array<UserComputersWithCustomizedFields>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<UserComputersWithCustomizedFields> }>(`${USER_COMPUTERS}`, {
        params: {
          customized_fields: customizedFields.join(','),
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getNewVersion() {
  return new Promise<NewVersionData>((resolve) => {
    pureInstance
      .get<string>('/next/version.txt')
      .then((res) => {
        resolve({
          version: res?.data,
        });
      })
      .catch(() => {
        resolve({
          version: null,
        });
      });
  });
}

function getUserComputersCount(teamId: number) {
  return new Promise<number>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { total: number } }>(`${USER_COMPUTERS}/accessible_count?team_id=${teamId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data.total);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getUserClientsCount(teamId: number) {
  return new Promise<number>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { total: number } }>(`${USER_CLIENTS}/accessible_count?team_id=${teamId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data.total);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

const verifyEmail = (requestedFrom: 'invite_user' | '2sv' | 'sos_package' | 'connect_with_web_client' = 'invite_user') => {
  return new Promise<string>((resolve, reject) => {
    axios
      .post<ResponseData & ResponseErrorReason>(`/api/web/v1/users/verify_email`, {
        requested_from: requestedFrom,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve('success');
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

function getFileBlob(url: string) {
  return new Promise<Blob>((resolve, reject) => {
    pureInstance
      .get<Blob>(url, { responseType: 'blob' })
      .then((res) => {
        const { data } = res;
        try {
          resolve(data);
        } catch {
          reject({ statusCode: 9487, messages: [], errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type InfoQueryData = Record<
  TeamKind,
  {
    team_id: number;
    team_member_permissions?: {
      sos_service_desk_premium_tool?: boolean;
    };
  }
>;

function getInfoQuery({ team_member_permissions }: { team_member_permissions: Array<'sos_service_desk_premium_tool'> }) {
  return new Promise<InfoQueryData>((resolve, reject) => {
    axios
      .post<ResponseData & { data: InfoQueryData }>(INFO_QUERY, {
        team_member_permissions,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export {
  getUserBasicProfile,
  queryPermissions,
  getAlertLogsUnreadCount,
  getAlertLogsUnread,
  acknowledgeAlertLog,
  acknowledgeAllAlertLogs,
  getSSOMethods,
  getSBADownloadUrl,
  getQuickSetupInfo,
  getCurrency,
  getPrices,
  getSRSDownloadLink,
  getUserComputersWithCustomizedFields,
  getNewVersion,
  getUserComputersCount,
  getUserClientsCount,
  verifyEmail,
  getFileBlob,
  getInfoQuery,
};
