import { useState } from 'react';

import { useTranslation } from 'next-i18next';

import { featureControl } from '@/feature/toggle';

import { RippleMenu, RippleMenuButtonGhost, RippleMenuItemSingleSelect, RippleMenuList } from '../RippleMenu';
import { RippleSingleSelect, RippleSingleSelectOption } from '../RippleSelect';

export const COMPUTER_VIEWS = {
  COMPUTER: 'COMPUTER',
  GROUP: 'GROUP',
} as const;
type ComputerViewKeys = keyof typeof COMPUTER_VIEWS;
export type ComputerViewValues = (typeof COMPUTER_VIEWS)[ComputerViewKeys];

export const useRippleComputerViewDropdown = () => {
  const [currentComputerView, setCurrentComputerView] = useState<ComputerViewValues>(COMPUTER_VIEWS.COMPUTER);

  return {
    currentComputerView,
    setCurrentComputerView,
  };
};

export const RippleComputerViewDropdown = ({
  currentComputerView,
  setCurrentComputerView,
}: {
  currentComputerView: ComputerViewValues;
  setCurrentComputerView: (key: ComputerViewValues) => void;
}) => {
  const { t } = useTranslation();
  const currentComputerViewName = (() => {
    if (currentComputerView === COMPUTER_VIEWS.COMPUTER) {
      return t('computer:computerView');
    } else if (currentComputerView === COMPUTER_VIEWS.GROUP) {
      return t('computer:groupView');
    }
  })();

  return (
    <>
      {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
        <RippleSingleSelect
          variant="ghost"
          size="xs"
          menuProps={{
            matchWidth: false,
          }}
          value={currentComputerView}
          onChange={(val) => {
            if (!val) return;
            setCurrentComputerView(val);
          }}
        >
          <RippleSingleSelectOption value={COMPUTER_VIEWS.COMPUTER}> {t('computer:computerView')}</RippleSingleSelectOption>
          <RippleSingleSelectOption value={COMPUTER_VIEWS.GROUP}> {t('computer:groupView')}</RippleSingleSelectOption>
        </RippleSingleSelect>
      ) : (
        <RippleMenu>
          {({ isOpen }) => {
            return (
              <>
                <RippleMenuButtonGhost isOpen={isOpen}>{currentComputerViewName}</RippleMenuButtonGhost>
                <RippleMenuList zIndex={5}>
                  <RippleMenuItemSingleSelect
                    isSelected={currentComputerView === COMPUTER_VIEWS.COMPUTER}
                    onClick={() => setCurrentComputerView(COMPUTER_VIEWS.COMPUTER)}
                  >
                    {t('computer:computerView')}
                  </RippleMenuItemSingleSelect>
                  <RippleMenuItemSingleSelect
                    isSelected={currentComputerView === COMPUTER_VIEWS.GROUP}
                    onClick={() => setCurrentComputerView(COMPUTER_VIEWS.GROUP)}
                  >
                    {t('computer:groupView')}
                  </RippleMenuItemSingleSelect>
                </RippleMenuList>
              </>
            );
          }}
        </RippleMenu>
      )}
    </>
  );
};
