const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#FFFFFF',
  blue: {
    0: '#F4F8FF',
    10: '#EBF2FC',
    20: '#D7E5FA',
    40: '#ACCBF4',
    60: '#82B0EF',
    80: '#5594EB',
    100: 'rgba(3, 109, 234, 1)', // #036DEA
    '100_0': 'rgba(3, 109, 234, 0.00)',
    '100_4': 'rgba(3, 109, 234, 0.04)',
    '100_8': 'rgba(3, 109, 234, 0.08)',
    '100_16': 'rgba(3, 109, 234, 0.16)',
    200: '#054BBF',
    300: '#06327F',
  },
  neutral: {
    10: '#F9F9F9',
    20: '#F5F6F7',
    40: '#EEF0F2',
    60: '#DFE4E9',
    80: '#C1C7CD',
    100: '#ACAFBB',
    200: '#9A9DB0',
    300: '#80859F',
    400: 'rgba(81, 87, 119, 1)', // #515777
    '400_0': 'rgba(81, 87, 119, 0.00)',
    '400_4': 'rgba(81, 87, 119, 0.04)',
    '400_8': 'rgba(81, 87, 119, 0.08)',
    '400_16': 'rgba(81, 87, 119, 0.16)',
  },
  green: {
    0: '#F6FAF6',
    10: '#ECFCED',
    20: '#DEFDE0',
    40: '#CBFCD1',
    60: '#97F5A9',
    80: '#77EF92',
    100: '#34DF66',
    200: '#14C02F',
    300: '#2DB44A',
  },
  red: {
    0: '#FAF6F7',
    10: '#FDE5EA',
    20: '#FBD5DE',
    40: '#F9C0CD',
    60: '#EE89A1',
    80: '#D42E56',
    100: 'rgba(198, 0, 46, 1)', // #C6002E
    '100_0': 'rgba(198, 0, 46, 0.00)',
    '100_4': 'rgba(198, 0, 46, 0.04)',
    '100_8': 'rgba(198, 0, 46, 0.08)',
    '100_16': 'rgba(198, 0, 46, 0.16)',
    200: '#B60022',
    300: '#9B0010',
  },
  yellow: {
    10: '#FFF9DF',
    20: '#FFF4C0',
    40: '#FBEB98',
    60: '#F9E07F',
    80: '#F8D65F',
    100: '#F8C81C',
    200: '#E0B007',
    300: '#C69A00',
  },
  dark: {
    10: '#F4F4F4',
    20: '#DDDDDD',
    30: '#C6C6C6',
    40: '#B0B0B0',
    50: '#999999',
    60: '#777777',
    70: '#606060',
    80: '#494949',
    90: '#333333',
    100: 'rgba(28, 28, 28, 1)', // #1C1C1C
    '100_0': 'rgba(28, 28, 28, 0.00)',
    '100_4': 'rgba(28, 28, 28, 0.04)',
    '100_8': 'rgba(28, 28, 28, 0.08)',
    '100_12': 'rgba(28, 28, 28, 0.12)',
  },
  magenta: {
    20: '#FFE1EB',
    100: '#FC276F',
  },
  orange: {
    10: '#FFDDDB',
    20: '#FFCAC4',
    40: '#FFAC94',
    60: '#FF9169',
    80: '#FB7D43',
    100: '#F57023',
    200: '#DA5516',
    300: '#BE3F0E',
  },
  purple: {
    0: '#E7DFFF',
    10: '#D9BFFF',
    20: '#CB9EFF',
    40: '#C688FF',
    60: '#AC59FF',
    80: '#8942E4',
    100: '#712ACB',
    200: '#4E278D',
    300: '#321C64',
  },
  legacyPurple: {
    100: '#80408A',
    200: '#67306E',
    300: '#4D2353',
  },
  highContrast: {
    red: '#FF0101',
    green: '#00FF01',
    blue: '#0001FF',
    cyan: '#00FFFF',
    magenta: '#FF01FF',
    yellow: '#FFFF01',
    purple: '#C688FF',
    mint: '#53FFBC',
    orange: '#FF9212',
  },
  highlight: {
    light: '#4B4BFA',
    dark: '#66F2FF',
  },
  lightBlue: '#64E1F6',
  article: {
    10: '#E6FFFF',
    20: '#DBFFFF',
    40: '#CBFFFF',
    60: '#B1F6FD',
    80: '#8FECF9',
    100: '#64E1F6',
    200: '#5CCEE5',
    300: '#4EABC2',
  },
} as const;

export default colors;
