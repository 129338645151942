import { BoxProps, Flex, forwardRef } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import { RippleArrowDown16, RippleArrowUp16, RippleInlineArrowDown, RippleInlineArrowUp } from '../RippleIcon';
import { RippleStrong } from '../RippleStrong';
import { RippleButtonText } from '../RippleText';

export type RippleInlineDropdownProps = BoxProps & {
  isOpen: boolean;
  isDisabled?: boolean;
  variant?: 'primary' | 'secondary';
  size?: 'lg' | 'md' | 'sm';
};

/**
 * @deprecated should use `RippleSingleSelect` with variant `ghost` instead.
 */
export const RippleInlineDropdown = forwardRef<RippleInlineDropdownProps, 'button'>(
  ({ isOpen, isDisabled, children, variant = 'primary', size = 'md', ...otherProps }, ref) => {
    const styleProps = getStyleProps();
    function getStyleProps(): FlexProps {
      let defaultColor = 'blue.100';
      let activeColor = 'blue.200';
      let hoverColor = 'blue.100';
      let currentColor = defaultColor;
      let currentHoverColor = hoverColor;

      switch (variant) {
        case 'primary': {
          defaultColor = 'blue.100';
          activeColor = 'blue.200';
          hoverColor = 'blue.100';
          currentColor = isOpen ? activeColor : defaultColor;
          currentHoverColor = isOpen ? activeColor : hoverColor;
          if (isDisabled) {
            currentColor = 'blue.40';
            currentHoverColor = 'blue.40';
          }
          break;
        }
        case 'secondary': {
          defaultColor = 'dark.90';
          activeColor = 'blue.200';
          hoverColor = 'dark.90';
          currentColor = isOpen ? activeColor : defaultColor;
          currentHoverColor = isOpen ? activeColor : hoverColor;
          if (isDisabled) {
            currentColor = 'dark.40';
            currentHoverColor = 'dark.40';
          }
          break;
        }
      }

      let padding = '1px 2px';
      switch (size) {
        case 'lg': {
          padding = '3px 6px';
          break;
        }
        case 'md': {
          padding = '1px 2px';
          break;
        }
        case 'sm': {
          padding = '0px 2px';
          break;
        }
      }

      return {
        padding,
        color: currentColor,
        _hover: {
          bg: isDisabled ? 'transparent' : 'blue.10',
          color: currentHoverColor,
        },
      };
    }

    const label = getLabel();
    function getLabel() {
      const sharedProps = {
        className: 'buttonText',
        color: 'inherit',
        mr: '4px',
      };
      switch (size) {
        case 'lg':
        case 'md': {
          return <RippleButtonText {...sharedProps}>{children}</RippleButtonText>;
        }
        case 'sm': {
          return (
            <RippleStrong as="p" {...sharedProps} variant="strong03" fontSize="12px" lineHeight="1rem">
              {children}
            </RippleStrong>
          );
        }
      }
    }

    const arrow = getArrow();
    function getArrow(): React.JSX.Element {
      switch (size) {
        case 'lg':
        case 'md': {
          return isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />;
        }
        case 'sm': {
          return isOpen ? <RippleInlineArrowUp color="inherit" /> : <RippleInlineArrowDown color="inherit" />;
        }
      }
    }

    return (
      <Flex
        as="button"
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        bg={!isDisabled && isOpen ? 'blue.20' : 'transparent'}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        ref={ref}
        {...styleProps}
        {...otherProps}
      >
        {label}
        {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
          <>{isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}</>
        ) : (
          arrow
        )}
      </Flex>
    );
  },
);
