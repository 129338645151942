import Router from 'next/router';
import React, { useCallback, useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';

import { LS_KEY_PCP_REFRESH_TOKEN, LS_KEY_PCP_REFRESH_TOKEN_EXP, checkValidSTPTeam, logout, validateAuthTokens } from '@/authentication';
import FullPageLoading from '@/components/FullPageLoading';
import { featureControl } from '@/feature/toggle';
import { BasicProfile, BasicProfileProvider } from '@/models/BasicProfile';
import { EmailVerificationModal } from '@/models/UserProfile/EmailVerificationModal';
import { getUserBasicProfile as getUserBasicProfileAPI } from '@/services/common';

import { infoQuery_queryMap } from './models/BasicProfile/constants';
import { infoQueryService } from './services/users/infoQueryService';

type AuthUserProviderProps = { children?: React.ReactNode };
export function AuthUserProvider({ children }: AuthUserProviderProps): React.JSX.Element {
  const { data, isFetching, isError, refetch } = useQuery<BasicProfile>({
    queryKey: ['authUser'],
    queryFn: async () => {
      await validateAuthTokens();
      const basicProfile = await getUserBasicProfileAPI();
      const infoQuery = featureControl.getToggle('PCP_4092__Add_info_query_data_into_basicProfileAtom')
        ? await infoQueryService.execute(infoQuery_queryMap)
        : {};
      return {
        ...basicProfile,
        infoQuery,
      };
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isError) {
    logout();
  }

  const handleRouteChangeStart = useCallback(() => {
    const refreshToken = localStorage.getItem(LS_KEY_PCP_REFRESH_TOKEN);
    const refreshTokenExp = localStorage.getItem(LS_KEY_PCP_REFRESH_TOKEN_EXP) || 0;
    if (!refreshToken || new Date().getTime() > Number(refreshTokenExp)) {
      refetch();
    }
    if (featureControl.getToggle('PCP_4105__RudderStack_add_team_info') && typeof window !== 'undefined' && window.rudderStackTracking)
      window.rudderStackTracking.page();
  }, [refetch]);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [handleRouteChangeStart]);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5QBCPHF' });
  }, []);

  useEffect(() => {
    if (featureControl.getToggle('PCP_4105__RudderStack_add_team_info')) return;
    try {
      if (data && data.profile) {
        const { email } = data.profile;
        const team = Object.values(data.teams)[0];
        window.rudderStackTracking.identify(email, {
          email,
          team_id: team?.team_id,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [data]);

  useEffect(() => {
    if (featureControl.getToggle('PCP_4105__RudderStack_add_team_info')) return;
    if (typeof window !== 'undefined' && window.rudderStackTracking) {
      window.rudderStackTracking.trackPageEvents();
      window.rudderStackTracking.fixOptimizelyTracking();
    }
  }, []);

  if (!data || isFetching) return <FullPageLoading />;

  const isSplashtopPersonal = Boolean(data?.teams?.stp);
  if (isSplashtopPersonal && !checkValidSTPTeam()) {
    // Some critical situation may causes infinite re-fetching API loop, add this to prevent it.

    refetch();

    return <FullPageLoading />;
  }

  return (
    <BasicProfileProvider basicProfile={data}>
      {children}

      <EmailVerificationModal />
    </BasicProfileProvider>
  );
}
